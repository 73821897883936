import React from 'react';
import { 
    Container, 
    Sticker,
    ContainerList,
    CaseLink,
    CaseStudyContainerList,
    CaseStudyContainer,
    CaseStudyContentList,
    CaseStudyContent,
    CaseStudyImage
} from '../StyledComponents/StyledComponents';
import { useEffect, useState } from 'react';
import marked from 'marked';

import { ClientText } from "../ListItem/Style";
import { Fade } from 'react-awesome-reveal';

import { MdOutlineDescription } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'

import AboutContent from '../../posts/about.md';
import climateReality from '../../assets/img/climatereality.png'
import ea from '../../assets/img/ea.png'
import { PALETTE_3 } from '../../assets/colors';

const Resume = () => {

    const [markdown, setMarkdown] = useState({});
    const [cR, setCR] = useState(false);
    const [eA, setEA] = useState(false);

    const onHover = (setHover) => {
        setHover(true);
    };

    const onHoverOver = (setHover) => {
        setHover(false);
    };

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    useEffect(() => {
        fetch(AboutContent)
            .then(response => {
                return response.text()
            })
            .then(text => {
                setMarkdown(marked(text))
        })
    }, [])

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        if (document.getElementsByClassName("twitter-embed")[0]) {
            document.getElementsByClassName("twitter-embed")[0].appendChild(script);
        }
    }, []);

    return (
        <Container>
            {/* Background */}
            <Container flex bottom={6}>
                <Container width={20} right={3} bottom={3}>
                    <h1>About</h1>
                    <div>
                    <Sticker src={climateReality} 
                        style={{width: "20%", maxWidth: "50px", marginRight: "5%", justifyContent: "center"}} 
                        onMouseEnter={() => onHover(setCR)}
                        onMouseLeave={() => onHoverOver(setCR)}
                    />
                    <Sticker 
                        src={ea} style={{width: "20%", maxWidth: "50px", marginRight: "5%"}}
                        onMouseEnter={() => onHover(setEA)}
                        onMouseLeave={() => onHoverOver(setEA)}/>
                    </div>
                    {cR && <i style={{lineHeight: ".75", margin: "0", fontSize: "15px"}}>Climate Reality Leader</i>}
                    {eA && <i style={{lineHeight: ".75", margin: "0", fontSize: "15px"}}>Effective Altruist</i>}
                    
                </Container>
                <Container width={80}>
                    {/*<div id={`post_About`} dangerouslySetInnerHTML={{__html: markdown}}></div>*/}
                    <p>I'm pursuing my undergrad in CS + Stat and Masters in Computer Science with a minor in European Studies. You can check out some of the things I've been thinking about (<a href="/blog">here</a>), like <a href="https://www.huusl.org/journal/community-land-trusts">Community Land Trusts</a>, why rent is so high in 2022, and how we can identify Mobility of Care Trips using gender as a proxy (<a href="https://www.researchgate.net/publication/365260630_Inferring_Mobility_of_Care_Travel_Behavior_From_Transit_Origin-Destination_Data">here</a>).
                     I also care a lot about getting women into STEM, particularly how we can increase retention rates <a href="https://www.thecrimson.com/article/2020/10/7/cs-tf-training/">for women studying CS</a>. 
                    </p>
                    <Fade direction="top" duration={1000} triggerOnce cascade>
                        <CaseLink target={null} styles={{justifyContent: 'center'}}>
                        <CaseStudyContainerList color={open ? PALETTE_3.PINK_2 : PALETTE_3.OLDROSE} favorite={false} styles={{justifyContent: 'center'}}>
                            <CaseStudyContentList onClick={toggle} styles={{transition: "height .25s linear", justifyContent: 'center'}}>
                                <ContainerList flexRow leftAlign>
                                    <h5 style={{marginTop: "1%", marginBottom: "1%", color:'white'}} hidden={open ? 'hidden': ''}>Some recent news...▶️</h5>
                                    <section className="twitterContainer" hidden={!open ? 'hidden': ''}>
                                        <div className="twitter-embed">
                                        <blockquote class="twitter-tweet"><p lang="es" dir="ltr">▶️<a href="https://twitter.com/mitmagob?ref_src=twsrc%5Etfw">@mitmagob</a> se ha reunido con el Instituto de Tecnología de Massachusetts (MIT), el Consorcio Regional de Transportes de Madrid y la <a href="https://twitter.com/La_UPM?ref_src=twsrc%5Etfw">@La_UPM</a> para intercambiar experiencias de la aplicación de inteligencia artificial y tecnologías bigdata al análisis de la movilidad. <a href="https://t.co/dKyhw4vwXZ">pic.twitter.com/dKyhw4vwXZ</a></p>&mdash; Ministerio Transportes, Movilidad y A. Urbana (@mitmagob) <a href="https://twitter.com/mitmagob/status/1627694686088445952?ref_src=twsrc%5Etfw">February 20, 2023</a></blockquote>
                                        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Really cool method using smartcard data to identify that people with female names are more likely to use bus stops near childcare, schools and grocery stores, from Daniela Shuman, <a href="https://twitter.com/ansoncfit?ref_src=twsrc%5Etfw">@ansoncfit</a> et al <a href="https://twitter.com/hashtag/TRBAM?src=hash&amp;ref_src=twsrc%5Etfw">#TRBAM</a> <a href="https://t.co/cPTDniuIR2">pic.twitter.com/cPTDniuIR2</a></p>&mdash; Dr Alexa Delbosc (@AlexaDelbosc) <a href="https://twitter.com/AlexaDelbosc/status/1612491626097659906?ref_src=twsrc%5Etfw">January 9, 2023</a></blockquote>
                                        </div>
                                    </section>
                                </ContainerList>
                            </CaseStudyContentList>
                        </CaseStudyContainerList>
                        </CaseLink>
                    </Fade>
                    <p>
                    What I do...
                    <ul>
                        <li>At the <a href="https://mobility.mit.edu/">MIT Mobility Lab</a>, I research mobility of care patterns: <i>I care about making public transit gender equitable</i>. </li>
                        <li>Effective Altruism - I care about making the world a better place (sustainability, urban development, global poverty)(<a href="https://www.harvardea.org/">EA</a>). </li>
                        <li>Harvard Open Data Project: Data Journalism is a powerful thing! I had my own hand in it, check it out here: (<a href="https://www.hodp.org/people/daniela-shuman/">HODP</a>)</li>
                        <li>Harvard Consulting for Business and the Environment: here are some projects I did as Director of Sustainability (<a href="https://www.harvardcbe.org/post/cbe-presents-chinese-investment-in-african-urban-transportation-infrastructure">CBE</a>)</li>
                    </ul>
                    What I've been doing...
                    <ul>
                        <li>I interned at Microsoft AI & Research wing in Bing Maps and in virtual reality technology.</li>
                        <li>I spent quite a bit of time working in various sustainability startups. Particularly, <a href="https://www.adaviv.site/">Adaviv</a> is an indoor farming technology company - I spent my gap year building a yield forecasting algorithm. </li>
                        <li>I spent my 2021 summer working at Facebook Reality Labs, at the New York office.</li>
                        <li>I predicted global population migration patterns and worked with global carbon emissions projections at the World Data Lab in Vienna during Summer 2022 (<a href="https://worlddata.io/">WDL</a>).</li>
                        <li>I worked as an Investment Engineer on the Sustainability Team at Bridgewater in 2023</li>
                    </ul>
                    </p>
                </Container>
            </Container>
            {/* Korean */}
            <Container flex bottom={6} style={{marginTop: "5%"}}>
                <h1>한국어</h1>
            </Container>
            <Container flex bottom={6}>
                <Container width={50}>
                    <u><a href="https://www.youtube.com/embed/1GWdqd-S6iE">2학기 동안</a></u> 공부했어요. 저는 한국 음악을 듣는 좋아해요.
                </Container>
            </Container>
            {/* Piano */}
            <Container flex bottom={6} style={{marginTop: "5%"}}>
                <h1>Piano + Singing</h1>
                <Container>
                    I played piano at the <a href="https://www.youtube.com/embed/PxcOJ6X9Yh0"> Pacific Northwest School </a> of music with Sydney Han Lee for 13 years! My maintained repertoire includes: 
                    <ul>
                        <li>Ballade No. 3, Chopin</li>
                        <li><u><a href="https://www.youtube.com/watch?v=iXBM4y0IhHA">Clair de Lune, Debussy</a></u></li>
                        <li>Arabesque No. 1, Debussy</li>
                    </ul>
                    <br/>
                    I recently started singing with the <a href="https://www.youtube.com/embed/cOMm34OoviY"><u>Radcliffe Choral Society</u></a> as a soprano.
                </Container>
            </Container>
        </Container>
    );
}

const PopUp = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    }

    return (
        <Fade direction="top" duration={1000} triggerOnce cascade>
            <CaseLink target={props.newTab ? '_blank' : null}>
                {!open && <CaseStudyContainerList color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContentList onClick={toggle} styles={{transition: "height .25s linear"}}>
                        <ContainerList flexRow leftAlign>
                            <h5 style={{marginTop: "auto", marginBottom: "auto"}}>{props.title}</h5>
                            {props.title}
                        </ContainerList>
                    </CaseStudyContentList>
                </CaseStudyContainerList>}
                {open && <CaseStudyContainer color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContent onClick={toggle} styles={{transition: "height .25s linear"}}>
                    <section className="twitterContainer">
                        <div className="twitter-embed">
                        <blockquote class="twitter-tweet"><p lang="es" dir="ltr">▶️<a href="https://twitter.com/mitmagob?ref_src=twsrc%5Etfw">@mitmagob</a> se ha reunido con el Instituto de Tecnología de Massachusetts (MIT), el Consorcio Regional de Transportes de Madrid y la <a href="https://twitter.com/La_UPM?ref_src=twsrc%5Etfw">@La_UPM</a> para intercambiar experiencias de la aplicación de inteligencia artificial y tecnologías bigdata al análisis de la movilidad. <a href="https://t.co/dKyhw4vwXZ">pic.twitter.com/dKyhw4vwXZ</a></p>&mdash; Ministerio Transportes, Movilidad y A. Urbana (@mitmagob) <a href="https://twitter.com/mitmagob/status/1627694686088445952?ref_src=twsrc%5Etfw">February 20, 2023</a></blockquote>
                        </div>
                    </section>
                    </CaseStudyContent>
                </CaseStudyContainer>}
            </CaseLink>
        </Fade>
    );
}

export default Resume;