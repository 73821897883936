import React from 'react';
import marked from 'marked';
import {
    BodyContainer,
    Container
} from '../StyledComponents/StyledComponents';
import Navigation from '../Navigation/Navigation.jsx';
import Footer from '../Footer/Footer.jsx';
import ProgressBar from '../ProgressBar/ProgressBar';
import ListItem from '../ListItem/ListItem';

import { useEffect, useState } from 'react';
import { db } from '../../firebase.js';
import { collection, onSnapshot, Timestamp } from "firebase/firestore"; 
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { FaArrowCircleRight } from 'react-icons/fa'

const tags = ["Favorites", "Memoirs", "My Thoughts", "All"];
const tagIds = ['favorite', 'memoir', 'description', 'all'];

const ListPost = (title, date, image, content, items, id) => {

    const [markdown, setMarkdown] = useState({});
    const [input, setInput]=useState('');
    const [list,setList]=useState([]);

    const [favorites, setFavorites] = useState([]);
    const [descOpen, setDescOpen] = useState(false)
    const [recents, setRecents] = useState([]);

    const openDesc = () => {
        setDescOpen(!descOpen); 
    }
    
    const [tag, setTag] = useState(0);

    const findTag = (ele) => {
        if (ele.tags) {
            return ele.tags.includes("favorite");
        }
    }

    useEffect(() => {
        fetch(title.content)
            .then(response => {
                return response.text()
            })
            .then(text => {
                setMarkdown(marked(text))
        })
    }, [])

    useEffect(() => {
        onSnapshot(collection(db, 'books'), (snapshot) => {
            snapshot.docs.map(doc => {
                let data = doc.data();
                setList(prev => [...prev, data]);//snapshot.docs.map(doc => doc.data()));
                if (findTag(data)){
                    setFavorites(prev => [...prev, data.title]);
                }
                let monthago = Timestamp.fromMillis(Date.now() - 604800000*6)
                if (data.date && data.date - monthago > 0){
                    setRecents(prev => [...prev, data]);
                }
            })
        })
        },[input]);
    
    if (list.length <= 0) {
        return (
            <Container>
                <ProgressBar />
                <Navigation />
                <BodyContainer>
                <Container small top={12}>
                    <h1>Books</h1>
                    <p>Hi friends :) I like to read occasionally, and per reading occasion, I typically write a bit about what I read. This is largely for two reasons: 1) I can reflect for myself; 2) I can share the beauties of knowledge and literature with others. </p>
                    <div style={{display: "flex", flexDirection: "horizontal", justifycontent: "left"}}>
                        <p>A bit about how I read: </p>
                        <button style={{padding: "2px", height: "0", marginLeft: ".5em", marginTop: "1.2em", background: "none", color: "inherit", border: "none", padding: 0,font: "inherit",cursor: "pointer", outline: "inherit"}}>
                            <FaArrowCircleRight onClick={openDesc}/>
                        </button>
                    </div>
                    {descOpen && 
                    <div id={`post_${title.title}`} dangerouslySetInnerHTML={{__html: markdown}}></div>}
                    <p>Loading...</p>
                </Container>
            </BodyContainer>
            </Container>
        )
    }

    return (
        <Container>
            <ProgressBar />
            <Navigation />
            <BodyContainer>
                <Container small top={12}>
                    <h1>Books</h1>
                    <p>Hi friends :) I like to read occasionally, and per reading occasion, I typically write a bit about what I read. This is largely for two reasons: 1) I can reflect for myself; 2) I can share the beauties of knowledge and literature with others. </p>
                    <div style={{display: "flex", flexDirection: "horizontal", justifycontent: "left"}}>
                        <p>A bit about how I read: </p>
                        <button style={{padding: "2px", height: "0", marginLeft: ".5em", marginTop: "1.2em", background: "none", color: "inherit", border: "none", padding: 0,font: "inherit",cursor: "pointer", outline: "inherit"}}>
                            <FaArrowCircleRight onClick={openDesc}/>
                        </button>
                    </div>
                    {descOpen && 
                    <div id={`post_${title.title}`} dangerouslySetInnerHTML={{__html: markdown}}></div>}
                    {/*<iframe src="https://firebasestorage.googleapis.com/v0/b/danielashuman-35529.appspot.com/o/blogs%2Fbooks.md?alt=media&token=8b6ea5e5-b155-486f-8510-2125c2b19f0a"/>*/}
                    <Container>
                        <p>Here are some recent reads...</p>
                        {recents.sort((a,b) => a.seconds - b.seconds).map((project) => {
                            return (
                                <ListItem
                                    route={project.title}
                                    thumbnail={project.image}
                                    title={project.title}
                                    author={project.author}
                                    description={project.description}
                                    tags={project.tags}
                                    length={project.length}
                                />
                            )
                        })}
                        {recents.length == 0 && 
                            <p>Unfortunately, no recent reads :(. This is either because I'm busy with classes or I haven't found a good book I've gotten through in a while... (recommendations always welcome!)</p>
                        }
                    </Container>
                    
                    <Tabs
                        value={tag}
                        onChange={(event, newValue) => setTag(newValue)}
                        variant="scrollable"
                        style={{margin: "5%"}}
                        inkBarStyle={{background: 'blue'}}
                    >
                        {tags.map(type => (
                        <Tab
                            disableRipple
                            label={type || "Untitled"}
                        />
                        ))}
                    </Tabs>
                    {list.map((project) => {
                        if (tagIds[tag] == 'all' || 
                            (project.tags && project.tags.includes(tagIds[tag])) || 
                            (tagIds[tag] == 'description' && project.description)) {
                            return (
                                <ListItem
                                    route={project.title}
                                    thumbnail={project.image}
                                    title={project.title}
                                    author={project.author}
                                    description={project.description}
                                    tags={project.tags}
                                    length={project.length}
                                />
                            )
                        }
                    })}
                </Container>
            </BodyContainer>
            <Footer />
        </Container>
    )
}

export default ListPost;
