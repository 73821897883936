import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import img1 from '../images/haussmann_1.png'

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<p>{props.date}</p>
			<div>
				<Image src={img1} style={{margin: 0}}/>
				<i>Boulevards of Paris, France - Daniela Shuman, 2022</i>
			</div>
			
<p>
	&emsp;Both Rio and Paris have a comparative history in the widening of streets in the early 1910s, where hundreds of buildings were demolished to make room for a rebuilding of the city. As these avenues were built, the cities transformed around them. Now, they make great Bus Rapid Transit lanes. This paper will explore the relationship of the Bus Rapid Transit systems between both cities by exploring two comparative boulevards. The Avenue de Presidente Vargas in Rio de Janiero hosts some of the busiest routes of the BRT system. The Boulevard Saint-Martin in Paris hosts part of Le Mobilien, Paris’ version of BRT. How did these similar histories influence the mobility systems that are within both cities now? We see that with the revolutionizing of city planning back in the 19th century, Hausmann has contributed to a legacy of modernization in both cities. Now, both Rio and Paris have implemented modern transportation systems, partly due to the ease of transition from the widened streets. 
<br/>&emsp;Rio has had a legacy of French influence on its design. Rio’s modernization after 1763 was an increasing dependence on French cultural influences (Underwood, 1991). With the turn of the century in 1900, Rio progressed into a phase of “Haussmannization”, in which the mayor Francisco de Pereira Passos implemented many reforms that took on the form of the French Baron Haussman implemented in France. This included massive slum clearance, demolitions and boulevard building, just as it had in Paris. 
<br/>&emsp;The Avenue Presidente Vargas was one of the results of this plan, and it is considered one of the largest, and most central avenues through Rio’s city center. It’s original purpose was to serve as a huge avenue that would work as a sort of urban expansion axis perpendicular to the Central Avenue (Brandão, n.d.). As the city grew rapidly in the 1920s, Avenue Presidente Vargas played a key role in controlling the urban sprawl. This avenue was originally part of the Agahe plan, developed by French architect-urbanist Donat-Alfred Agache (Underwood, 1991). The purpose of the Agache plan, and further the plan for the Avenue President Vargas, was to open out the city for health reasons. 
<br/>&emsp;In the 1950s, the purpose of the avenue transformed into efficient circulation of people and vehicles through the boulevard. Viaducts were built to enlarge the avenue (Borde et al., 2010), and public transportation in general began to be prioritized. This led relatively nicely into the implementation of the bus rapid transit system on the avenue. Politically, because of the gradual transformation of priority, adding bus-only lanes was politically much more viable. 
<br/>&emsp;As of now, there are additional Bus Rapid Transportation lines planned for this avenue. (Reporter, 2011). Because of the direct direct cut through the city, these BRT lines use the wide avenue as an effective and flexible way to get through the city (Hensher & Golob, 2008). Currently, the Metro 1 BRT line, Linha Belford Roxo de SuperVia and Linha Deodoro da SuperVia are both along the Avenue (BRT Bus Rio de Janeiro Map - Map of BRT Bus Rio de Janeiro (Brésil), 2020). The history of prioritizing throughput led to the BRT being a clear choice of transit for the avenue. 
<br/>&emsp;Paris has had a similar historical context, although has not had as recent of a history with public transportation on the boulevards. Between 1853 and 1870, Haussmann played a large role in the renovation of Paris. For many of the same reasons Rio underwent a renovation, Paris was overcrowded, with very narrow streets, and many argued for a renovation. Haussmann found an opportunity to implement his plan and widen boulevards, avenues, and add new parks and squares. Hausmann followed a relatively revolutionary Paris leader, Napoleon III, who added 80km of new boulevards (The Man Who Designed Paris, 2019). Hausmann is still considered one of the most influential figures in Paris Urban design because of the wide boulevards and avenues (Jagannath, n.d.). Although not well documented, Hausmann’s widening of streets led to at least one boulevard, the rue Saint Christophe, to be widened by about 2.6 meters, or about 8 feet, or another lane of traffic, as documented. It is likely that most of the other boulevards under Hausmann’s control were extended similarly (Paccoud, 2016). Hausmann’s legacy was a large opening of the Paris city central, in the later 19th century. Currently, the Champs-Elysees, one of the largest Paris Boulevards is 70 meters long (Why Are the Streets So Wide in Paris?, n.d.). 
<br/>&emsp;In the 1920s, Paris boulevards hosted the first bus lines, first developed in 1906 (Laurian, 2012). This timing was relatively similar to Rio’s. These widened boulevards were originally used to bring air to the city, considered by Haussman, although the modern purpose has drastically changed. Modern Bus System was developed in 2001 (Laurian, 2012). The Mobilien buses drive on separated lanes and are Paris’s version of a Bus Rapid Transit system. This was developed for the first time in 2008. This was a large project to offer more space to buses, pedestrians, and bikes to the detriment of car lanes. An example of one of these boulevards is the Boulevard Saint-Martin which ends up in the Place de la Republique. This boulevard connects four bus lines but was widened originally by Haussmann. The influence of the widening of the streets can be clearly seen through the historical lens of Haussmann. Paris has been continuously transformed to be an modern city, and through the widening of the streets, Paris has continued to do so in its transportation systems. 
<br/>&emsp;Both systems were highly influenced by Haussmann and developed relatively similarly over the course of the century, with the prioritization of public transportation. Both of these example streets connect the central district to other central districts, while still maintaining neighboring and lining communities as mixed-use. With the purpose of “airing out” the city, the implications of Hausmann’s work reach beyond health in the 19th century, but to a more flexible transition to modern transportation methods in planning. Back in the 19th century, these transitions were revolutionary, and thus, has contributed to a continued modernization of both cities. A widening of streets allowed for the room for multiple modes of transit, leading to an easier transition to Bus Rapid Transportation as a viable public transportation option. 

</p>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;