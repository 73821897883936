import React from 'react';
import Posts from "../posts/Posts";
import Article from '../components/Article/Article';
import { 
    BodyContainer, 
    Container,
    CaseLink} from "../components/StyledComponents/StyledComponents";
import Navigation from '../components/Navigation/Navigation.jsx';
import Footer from '../components/Footer/Footer.jsx';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import ArticleItem from '../components/ArticleItem/ArticleItem';

import { useEffect, useState } from 'react';
import { db } from '../firebase.js';
import { collection, onSnapshot, Timestamp } from "firebase/firestore"; 

const Blog = () => {

    const [list, setList] = useState([]);
    const [input, setInput]=useState('');

    const tags = ['urban', 'transportation', 'sustainability', 'other']
    const titles = ['Urban Issues', 'Transportation', 'Sustainability', 'Other']

    useEffect(() => {
        onSnapshot(collection(db, 'projects'), (snapshot) => {
            snapshot.docs.map(doc => {
                let data = doc.data();
                setList(prev => [...prev, data]);//snapshot.docs.map(doc => doc.data()));
            })
        })
        },[input]);

    return(
        <>
        <ProgressBar/>
        <Navigation page="about" />
        <BodyContainer style={{marginTop: "10%"}}>
				<h1>My Thoughts...</h1>
                <p>I spend a lot of time thinking about urban issues. Check out what I've worked on below!</p>
                {tags.map((tag, i) => {
                    return (
                    <div>
                    <h3>{titles[i]}</h3>
                    <Container>
                        {list.sort((a,b) => b.timestamp - a.timestamp).map((project) => {
                            if ((tags[i] == 'other' && project.tags && 
                                !project.tags.includes("urban") && 
                                !project.tags.includes("sustainability") && 
                                !project.tags.includes("transportation")) ||
                            (project.tags && project.tags.includes(tags[i]))) {
                        return (
                            <ArticleItem
                                route={project.route}
                                thumbnail={project.image}
                                subtitle={project.subtitle}
                                title={project.title}
                                date={project.date}
                                description={project.description}
                                hasData={project.tags.includes("statistics")}
                            />
                        );    
                        }})}
                    </Container>
                    </div>
                )})}
        </BodyContainer>
        <Footer/>
        </>
    )
}

export default Blog;
