export const BLUE_1 = "#325A9Bff";
export const BLUE_2 = "#4F70A3ff";
export const BLUE_3 = "#6A9EE4ff";
export const BLUE_4 = "#D9EAF8ff";
export const GREEN_1 = "#4E625Eff";
export const LAVENDAR_1 = "#f0e3f3";

export const PALETTE_1 = {
    DARKER: "#1e3231ff",
    DARK: "#485665ff",
    MID: "#8e7c93ff",
    LIGHT: "#d0a5c0ff",
    LIGHER: "#f6c0d0ff"
};

// My Favorite, Lilac Palette
export const PALETTE_2 = {
    PURPLE_1: "#544f87",
    PURPLE_2: "#abb2c7",
    MID: "#cfd4d2",
    GREEN_2: "#a1b176",
    GREEN_1: "#586c0c" 
}

// pink

export const PALETTE_3 = {
    GREY: "#ECE6E8",
    PINK_2: "#F1E4E8",
    THISTLE: "#CEB1BE",
    OLDROSE: "#B97375",
    RAISINBLACK: "#2D2D34",
}