import React from 'react';
import marked from 'marked';
import {
    BodyContainer,
    Container,
    ContainerGrid
} from '../StyledComponents/StyledComponents';
import Navigation from '../Navigation/Navigation.jsx';
import Footer from '../Footer/Footer.jsx';
import ProgressBar from '../ProgressBar/ProgressBar';
import GalleryItem from '../GalleryItem/GalleryItem';
import {Tabs, withStyles} from '@material-ui/core';
import Tab from "@material-ui/core/Tab";

import { useEffect, useState, useCallback } from 'react';

import { db } from '../../firebase.js';
import { collection, onSnapshot } from "firebase/firestore";


const CenteredTabs = withStyles({
    flexContainer: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  })(Tabs);

const MusicPost = (title) => {

    const [markdown, setMarkdown] = useState({});
    const [input, setInput]=useState('');
    const [list,setList]=useState([]);

    const [expandedItem, setExpandedItem] = useState(null);
    const [tag, setTag] = useState(0);

    const tags = ['Hip-Hop', 'Classical', 'Other']
    const tagIds = ['hiphop', 'classical', 'other']

    const handleItemClick = (index) => {
        // Toggle the expanded state for the clicked item
        if (expandedItem != null || index === expandedItem) {
            setExpandedItem(null); // Collapse the item if it's already expanded
        } 
        else {
          setExpandedItem(index); // Expand the clicked item
        }
      };

    useEffect(() => {
        fetch(title.content)
            .then(response => {
                return response.text()
            })
            .then(text => {
                setMarkdown(marked(text))
        })
    }, [])

    useEffect(() => {
        onSnapshot(collection(db, 'music'), (snapshot) => {
            snapshot.docs.map(doc => {
                let data = doc.data();
                setList(prev => [...prev, data]);
            })
        })
    },[input]);

    const galleryItemStyle = {
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        transform: 'none', // Reset transform for collapsed items
        transformOrigin: 'center', // Center the scaling transformation
      };
    
      const expandedItemStyle = {
        gridColumn: 'span 2',
        transition: 'transform 0.3s ease',
        transformOrigin: 'center',
      };
    
    if (list.length <= 0) {
        //potentially add graph of most listened to artists
        return (
            <Container>
                <ProgressBar />
                <Navigation />
                <BodyContainer style={{margin: "10%"}}>
                <Container>
                    <h1>Music</h1>
                    <p>In addition to playing piano and singing, I'm quite the aficianado of breaking down albums by some of my favorite artists.</p> 
                </Container>
            </BodyContainer>
            </Container>
        )
    }

    return (
        <Container>
            <ProgressBar />
            <Navigation />
            <BodyContainer style={{marginTop: "10%"}}>
                <Container>
                    <h1>Music</h1>
                    <p>In addition to playing piano and singing, I'm quite the aficianado of breaking down albums by some of my favorite artists.</p>
                    <CenteredTabs
                        value={tag}
                        onChange={(event, newValue) => setTag(newValue)}
                        variant="scrollable"
                        style={{margin: "5%"}}
                        inkBarStyle={{background: 'blue'}}
                        centered
                    >
                        {tags.map(type => (
                        <Tab
                            disableRipple
                            label={type || "Untitled"}
                        />
                        ))}
                    </CenteredTabs>
                    {list.map((project, index) => {
                        if (tagIds[tag] == 'all' || 
                            (project.tags && project.tags.includes(tagIds[tag])) ||
                            (tagIds[tag] == 'other' && !project.tags)) {
                            return (
                                <GalleryItem
                                    route={project.title}
                                    thumbnail={project.src}
                                    title={project.name}
                                    timestamp={project.timestamp}
                                    author={project.artist}
                                    description={project.description}
                                    tags={project.tags}
                                    length={project.length}
                                    left={index % 2 != 0}
                                    open={index == expandedItem}
                                    onClick={() => handleItemClick(index)}
                                />
                            )
                        }
                    })}
                </Container>
            </BodyContainer>
            <Footer />
        </Container>
    )
}

/* 

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
//import { photos } from "./photos";

import { photos } from "../../assets/photos"
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const isMobile = width <= 768;

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

<Gallery photos={list} onClick={openLightbox} />
                    {!isMobile ? (
                        <ModalGateway>
                        {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                            currentIndex={currentImage}
                            views={list.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.description
                            }))}
                            />
                        </Modal>
                        ) : null}
                    </ModalGateway>) : null}*/

export default MusicPost;