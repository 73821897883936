import styled, { css } from 'styled-components';
import { PALETTE_3 } from '../../assets/colors';

export const NavContainer = styled.div`
    position: fixed;
	left: 0;
    right: 0;
    top: 0;
    opacity: ${props => props.opacity};
    background: #CEB1BE;
    z-index: 10;

    @media (max-width: ${props => props.theme.queries.desktop}) {
        position: relative;
	}
`;

export const Nav = styled.div`
    height: 100px;
    width: 100%;
    max-width: ${props => props.theme.queries.desktop};
	margin: 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	   -ms-flex-pack: justify;
    justify-content: space-between;
	-webkit-box-align: center;
	   -ms-flex-align: center;
          align-items: center;
`

export const DesktopLogo = styled.span`
    display: inline;
    color: white !important;
`;

export const MobileLogo = styled.span`
    display: none;
    color: white !important;
`;

export const NavLogo = styled.a`
    cursor: pointer;
    -webkit-transition: ${props => props.theme.transition};
         -o-transition: ${props => props.theme.transition};
            transition: ${props => props.theme.transition};
    z-index: 2;
    color: white;
    font-weight: 500;

    &:hover {
        -webkit-transform: scale(1.05); 
            -ms-transform: scale(1.05);
                transform: scale(1.05);
    }

    &:active {
        -webkit-transform: scale(0.98); 
            -ms-transform: scale(0.98);
                transform: scale(0.98);
    }



    @media (max-width: ${props => props.theme.queries.mobile}) {
        ${MobileLogo} {
            display: inline;
        }

        ${DesktopLogo} {
            display: none;
        }
    }
}`;

export const SubNavMenu = styled.div`
    display: flex;
`

export const SubNavMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: flex;

    @media (max-width: ${props => props.theme.queries.mobile}) {
        display: none
    }
`

export const SubNavMenuListMobile = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: none;

    @media (max-width: ${props => props.theme.queries.mobile}) {
        display: flex
    }
`

export const SubNavMenuListItem = styled.li`
    display: ${props => props.opacity === 0 ? "none" : "block"};
    position: relative;
    margin-left: 2rem;

    a {
        display: ${props => props.opacity === 0 ? "none" : "block"};
        position: relative;
        color: ${PALETTE_3.OLDROSE} !important;
        text-decoration: none;
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;

        &:hover,
        :focus,
        :active {
            color: #B97375 !important;
    }

    ${props => props.active &&
        css`
            color: #B97375 !important;
        `
    }
`

export const Hamburger = styled.div`
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: none;
  @media (max-width: ${props => props.theme.queries.mobile}) {
    display: block;
  }
  position: relative;
  width: 30px;
  height: 30px;
  transform: ${(props) => (props.slide ? "translateX(-1vw)" : null)};
`;

export const Bar = styled.p`
  border-radius: 25px;
  height: 3px;
  background-color: ${PALETTE_3.OLDROSE};
  margin: 6px 0;
  transition: all 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: ${(props) => 30 - props.num * 7 + "px"};
  /* width: 30px;  */
  transform: ${(props) =>
    props.rotate1 ? "translateY(9px) rotate(-45deg)" : null};
  transform: ${(props) => (props.rotate2 ? "rotate(45deg)" : null)};
  transform: ${(props) =>
    props.rotate3 ? "translateY(-9px) rotate(45deg)" : null};
  width: ${(props) => (props.slide ? "30px" : 30 - props.num * 7 + "px")};
`;

export const SideBar = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 5%;
  text-align: right;
  display: ${(props) => (props.show ? "block" : "none")};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  & a {
    font-size: 16px;
    text-decoration: none;
    transition: 0.2s;
    font-weight: 600;
  }
  & p {
    margin-bottom: 15px;
  }
`;