import React from 'react';
import Navigation from '../components/Navigation/Navigation.jsx';
import HomeHero from '../components/Home/HomeHero';
import HomeArticles from '../components/Home/HomeArticles';
import Footer from '../components/Footer/Footer.jsx';
import { PALETTE_3 } from '../assets/colors';
import { HeroContainer } from '../components/StyledComponents/StyledComponents.jsx';

const Home = () => {
    return (
        <div style={{background: `${PALETTE_3.GREY}`}}>
            <Navigation page="work" />
            <HomeHero />
        </div>
    );
}

export default Home;