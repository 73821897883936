import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<p>{props.date}</p>

			<p>
			<br/>&emsp;Berlin is a constantly evolving city; when a problem arises, the city is thoughtful about being immediately responsive. This certainly applied during the pandemic, where demand rose for alternative mobility options to avoid infectious spread. Berlin responded by building temporary bike lanes, making biking a significantly more desirable option for many residents, even with the approach of winter. The 15km of lanes were so successful that bike traffic rose by 25% in Berlin (ADFC, 2021). Berlin has made several of these bike lanes permanent within the last year. This article explores effective urban experimentation through the lens of the temporary mobility infrastructure of pop-up bike lanes in Berlin. 
			<br/>&emsp;Temporary projects in Berlin have been part of the design fabric of the city. (Berlin Strategy - Urban Development Concept Berlin 2030, 2021). Berlin consistently embodies the “urban virtues of tolerance, experimentation, and irreverence” (Ladd & Steinisch, 1997, p. 225). The IBA Berlin 2020 architectural exhibition frames Berlin as a laboratory for generating a new kind of European City (Senate Department for Urban Development, 2020) through lenses of “re-imagining ecologically balanced city” and a “socially-mixed city & socially-responsive city”. The methods for Berlin to be “socially-responsive” is by creating a series of temporary use projects, responding to societal needs. 
<br/>&emsp;The pop-up bike lanes were implemented under the umbrella of the IBA Berlin strategy as an example of a socially responsive project. Berlin was already on the path to developing more bicycle lanes and this momentum was accelerated with the demand for alternative mobility options during the pandemic. The entire implementation process of over 15 kilometers of bike lanes took only a few weeks (Winkler, 2020). The first step was identifying a list of prioritized locations. The implementation process for each location had a time limit of 48 hours. This process was overseen by local authorities who signed-off the measures directly on-site to dramatically expediate the process. This included painting the bike lanes onto the streets and adding necessary protective infrastructure. Finalizing traffic signage was completed within 24 hours. Adjusting and modifications were included in the plan, in which evaluation would last 72 hours: assessment of reduced risk of infection, traffic safety, and traffic flow at each location (Making Safe Space for Cyclists in 10 Days, 2020). 
<br/>&emsp;These pop-up bike lanes are great examples of socially responsive temporary mobility infrastructure. The city identified a problem and implemented a quick fix. Pop-up bike lanes seem to be a great solution, at first glance, to the mobility disparity caused by the pandemic (Pop-up Bike Lanes in Berlin | Use, 2020). Many lower-income or non-vehicle owning individuals were in more danger because of the pandemic exposure on the bus and rail systems. Improving the viability of biking as a mobility option quickly directly addresses these equity gaps in mobility across the city. The 15km of lanes were so successful, bike traffic rose by 25% in Berlin (ADFC, 2021), following only a 9% increase in the year prior (Smee, 2019). 
<br/>&emsp;However, some critics of the pop-up bike lanes address larger issues with temporary mobility infrastructure. Typically, temporary mobility infrastructure can a) bypass regulation, b) miss perspectives of key stakeholders; and 3) cause unintended consequences. These pop-up bike lanes were relatively informal, given they were able to bypass some of the regulation (Pop-up Bike Lanes in Berlin | Use, 2020). Eight of the 14 lanes were found to be implemented illegally. Further, the automotive industry, a key stakeholder of Berlin streets, strongly against the implementation of these bike lanes, claiming it stifled innovation (Cantrill, 2020). Because of the car dependent economy, the roads in Berlin, as experienced by private vehicles, play a large role in the culture of the city (Cantrill, 2020). Temporary projects are also designed to solve specific problems and can result a lack of proper oversight to avoid unintended consequences. These pop-up bike lanes were implemented for the purpose of offering an alternative form of transportation to reduce the spread of COVID through other modes of mobility. It was expected that these bike lanes would accelerate the process towards better biking infrastructure and reduce the usage of cars. However, the decrease in car usage was largely attributed to the pandemic, and car ownership increased with the population growth of the last year (Cantrill, 2020) (Statista, 2021). 
<br/>&emsp;Even with all of this criticism, the public responded relatively positively to the implementation of the pop-up bike lanes. Twitter is one powerful tool for gauging public perception of temporary projects, especially for getting immediate results. Researchers and planners have validated the usefulness of Twitter through multiple different projects (Portland State University et al., 2017). Twitter is often used in conjunction with a sentiment analysis to evaluate the public perception. Twitter was verified by a team of researchers at Michigan State University as a viable source (Li et al., 2021). Further, many transit agencies use Twitter to respond immediately to issues with service. A number of US transit agencies use Twitter to directly respond to riders (Raschke, 2011) (Davies, 2015). Berliners also use Twitter regularly to notify S-Bahn (@s_bahn_berlin) and the underground (@BVG_Ubahn) of service issues, relatively immediately. Transit agencies in Berlin use this platform to announce temporary changes and let the public forum take its course to evaluate the perceptions of the changes. 
<br/>&emsp;Twitter as an immediate evaluation tool for temporary project success can limit the voices of key groups. 37% of Germans are over 55, meaning that a large number of potential users of the temporary infrastructure project would be underrepresented in the evaluation process, given that Germans over 55 don’t utilize social media nearly as much as younger generations (Agency, 2019). Other methods of gauging “success” of a temporary project are important to supplement the results. 
<br/>&emsp;Using social media anecdotally to evaluate the pop-up bike lanes has been a relatively useful tool. Advocate groups across the city have been very happy about the progress to temporary infrastructure, particularly for the COVID-safe mobility options. The advocate group FaireStraßen (meaning Fair Streets) is relatively active on social media with the hashtag #FaireStrassen. FaireStraßen, among other advocacy and transit progressives, have responded very happily about the implemented bike lanes, claiming it is a step towards more permanent better biking infrastructure. Individuals unaffiliated with the group have also tweeted positively, complete with proud photos of the freshly painted lanes. 
<br/>&emsp;Ultimately, the entire process of urban experimentation with temporary bike lanes was implemented smoothly and achieved many of its goals, including a relatively positive response from the public. Applying this model of temporary implementation for urban experimentation in other cities is very possible, acknowledging the historical advantage Berlin had to result in an effective experimentation. There is an existing historical precedent in Berlin given the experimental culture of the city for generations. Berliners are familiar with the urban landscape changing quickly in their city, which may not be the case in other cities. That said, other cities have alternative advantages, especially cities with a younger population or more active social-media users. Cities should seriously consider the plans laid out in Berlin for implementing various forms of safer and greener mobility infrastructure.

			</p>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;