import React from 'react';
import {
    Container,
    HeroContainer, 
    Image
} from '../StyledComponents/StyledComponents';
import headshot from '../../assets/img/Daniela_2.png'

const AboutHero = () => {
    return(
        <HeroContainer
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 2, delay: 1 }}
            exit={{ opacity: 0 }}
        >
            <div>
                <Container bottom={3} top={3}>
                    <h1>I'm a cities nerd who can do a little data science :)</h1>
                </Container>
            </div>
            <Image src={headshot} style={{margin:"0%", objectFit: "cover",  width: "40%", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}} />
        </HeroContainer>
    )
}

export default AboutHero;
