import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import img1 from '../images/elcano_1.png'
import img2 from '../images/elcano_2.png'
import img3 from '../images/elcano_3.png'
import img4 from '../images/elcano_4.png'
import img5 from '../images/elcano_5.png'
import img6 from '../images/elcano_6.png'
import img7 from '../images/elcano_7.png'
import img8 from '../images/elcano_8.png'

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<p>{props.date}</p>
<h2>Background </h2>
<p>
El Caño de Martin Peña is a lively community in San Juan, Puerto Rico. Migrants from around the island moved into the area in the 1930s and 40s for economic opportunity. El Caño has been plagued with poor living conditions (little access to clean water, frequent flooding, poor sanitation). In the past 5 years, the San Juan government finally voted to grant El Caño land rights in the form of a Community Land Trust. This means that an organization owns the land, and individuals own their housing structures. Proyecto ENLACE is a non-profit organization dedicated to improving the community – building infrastructure, repairing sanitation and plumbing systems. 
<br/>
<br/>
After reading about El Caño de Martin Peña and Proyecto ENLACE in SUP601, I spent the day around the community, led by community leader Jean. Jean works for Proyecto ENLACE as an environmental coordinator. Jean and I met at an elementary school Moises Melendez, which was currently undergoing renovations. 
</p>
<h2> El Caño </h2>
<p>
There is a divergence between official boundaries and the differences between the communities. The US government split El Caño into 8 districts, vaguely based off of existing community divisions. 
</p>
<Image src={img1}/>
<p>
However, there is no real difference between some of the communities drawn up by the road. Calle C separates Las Monjas and Buena Vista, but the community refers to both as Las Monjas. In the north, Barrio Obrero, which means workerhood), is considered one community, even though it is officially split between the north and south. Israel and Bitumul are officially considered one district, but Israel is closer to El Caño and is more spread-out than the denser Bitumul. 
In the south, Parada27 is Jean’s personal favorite district. It has wider streets, and each house contains more space. Las Monjas contains a community center that holds events or workshops. Last December, a group of students from Columbia University held a study in this community center to collect data on asthma rates in children. 
We then drove up to the north. Barrio Obrero Oeste is an official district, so it contains direct funding from the state. There are sidewalks and road signs that visually separate this district from the others. The road that splits the Barrio Obrero communities officially is wide and has traffic in both directions. It is lined with bodegas and small restaurants. Barrio Obrero Marina doesn’t have any crossroads; all the streets move north/south and have one way traffic, requiring us to turn around at the end of each road. Down one of the streets is a cleared-out beach to the El Caño; it is Jean’s favorite spot within the entire community. Within the next ten years, there is a plan to create a walking path through the mangroves along the water. People who own these waterfront houses sometimes own boats as well and use El Caño to travel out to the ocean to fish. Jean mentioned he wants to get a house in this part of the community. He is convinced this land value is going to increase dramatically, and he would love to own a house there. 
<br/>
<Image src={img2} style={{margin: 0}}/>
<i>Figure 2 Beach on El Caño in Barrio Obrero Marina</i>
</p>

<h2>Education</h2>
<p>
We spent most of our time during the day, so the students were in school. Around 3pm, students started trickling in with their backpacks, walking around the community, likely to their home. 
There are 4 elementary schools and one high school. Students in middle school travel outside the area to attend school. Recently, one of the schools was forced to close by the education department. One of the closed schools was transformed into the office of the G8. 
Buena Vista Santurce contains a community center painted in beautiful colors. Students come to this center to get help with school. This is funded by various grants the community center receives. 

<br/>
<Image src={img3} style={{margin: 0}}/>
<i>Figure 3 Community Center in Buena Vista Santurce</i>
<br/>
</p>
<h2>Community</h2>
<p>
As we drove through each district, Jean would roll down the window once every few minutes and shout some pleasantries to someone outside. The community is made up of “Tias” (“Tia” means aunt in Spanish), who hold bingo nights and other events for the people in their region. Some of these “Tias” are presidents of their district, or members of the district board. 
As we drove through Israel, I met Evelyn, who talked passionately with Jean about protecting the community from a recent increase in trash. A bicycler who was an obvious outsider to Evelyn caught the eye of everyone. Evelyn commented on how to reduce the trash from being strewn around the community. 
In regard to trash, in Barrio Obrero Marina there is a community run waste system. The entire operation is financially self-sustaining. Trash is collected from across the entire community and is sold to waste disposal plants. We stopped to give our greetings to the person running the operation. 
Jean was particularly proud of the community gardens throughout the community. There are multiple gardens, some more supported by the G8 and ENLACE than others. The garden below has coffee, breadfruit, beans, and more. This garden is located in Las Menjas. 

Written signs of white text on black paper are plastered around the community, on fences or written on walls. One of the signs said: “Los chicos y Jovenes tienen voz y voto” (the kids and youth have a voice and a vote). 
<br/>
<Image src={img5} style={{margin: 0}}/>
<i>Figure 5 Sign around community</i>
<br/>
In the afternoon, I spent about an hour walking around the community, largely in Las Menjas. I sat down nearby a bar, slightly fatigued from the heat, but also just observing the laughter and music coming from inside. After a few minutes, a lady came up carrying a cold-water bottle and a snack approached me in English and handed me the refreshments. The community will treat you like family, even if you are not one of them. 

<br/>
<br/>
<h2>Governance Structure</h2>
The G8 is made up of 8 presidents representing the 8 districts in El Caño. G8 is named after the 8 grupos. Each district also has a board of other volunteers who do work within their district. The G8 office building is a converted elementary school. Here, projects are proposed to all 8 presidents, who must all vote to approve the project. Often, proposed projects pass with amendments or changes. These projects will be proposed by ENLACE, often in partnership with governmental organizations such as the EPA. ENLACE and G8 are separate organizations. They employ people separately and have different offices.
<br/>
<Image src={img6} style={{margin: 0}}/>
<i>Figure 6 Office of G8</i>
<br/>
ENLACE is called “the corporation” by members within the community. People that work for ENLACE are not necessarily residents of El Caño, and they don’t have power to make decisions without approval of the G8. ENLACE will find funding and projects and then propose these projects as discussed above. For example, Jean will receive an email from an governmental organization about an improvement project (often times paid for by the organization), and will present this project along side the org to G8. 
<br/>
<Image src={img7} style={{margin: 0}}/>
<i>Figure 7 Office of ENLACE</i>
<br/>
The Fidecomiso de La Tierra (Community Land Trust) has an office in the northern part of the community. In the office, the land and housing rights are managed.  

The dredging hasn’t fully started because first, residents in risky areas need to be relocated. Many of these relocations were happening in the Israel community. Additionally, many communities still didn’t have proper sanitation or clean water, this was a priority before the dredging could begin. Currently, a pipe is being routed from the south to the north underneath El Caño. 
Schools are being improved. Over the past week, in front of the Capital building, hundreds of people have been protesting for better retirement packages for teachers. A common phrase around the city recently was “Sin maestras no hay future” (without teachers there is no future). The University of Puerto Rico has a required class for medical students in their last year to immerse in communities of poverty. These medical students were painting the Escuela Moises Melendez, an elementary school. Students came to this school in the afternoons, from ages 6 to 12. In one of the rooms being painted, white boards contained phrases such as “Hoy es meircoles. Ayer fue martes. Mañana será jueves.” (“Today is Wednesday. Yesterday was Tuesday. Tomorrow will be Thursday”). 
</p>

<h2>Conclusion</h2>
<p>As we were driving to El Restaurante Obreros (the workers restaurant), I asked him what he thought the secret was to El Caño’s community organizing. He said the community leaders. The community leaders will always have the final say on any proposed projects. Any improvements in El Caño will be directly for the community. </p>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;