import React, { useState } from 'react';
import {
    ContainerList,
    Container,
    CaseLink,
    CaseStudyContainerList,
    CaseStudyContainer,
    CaseStudyContentList,
    CaseStudyContent,
    CaseStudyImage,
    Button,
    Tag
} from '../StyledComponents/StyledComponents';
import { ClientText, Badge } from "./Style";
import { Fade } from 'react-awesome-reveal';

import { MdOutlineDescription } from 'react-icons/md'
import {HiOutlinePresentationChartLine} from 'react-icons/hi'

import { BiTimeFive } from 'react-icons/bi'
import { PALETTE_3 } from '../../assets/colors';

const PrimaryButton = (props) => {
    return(
        <a href={props.route} target={props.newTab ? '_blank' : null}>
            <Button padding="0rem .5rem" color={PALETTE_3.OLDROSE} onClick={props.onClick} type={props.submit ? "submit" : "button"} right={props.right ? true : false} left={props.left ? true : false}>
                <i className={`las la-${'arrow-right'}`}></i>
            </Button>
        </a>
    )
}

const Project = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        if (props.description) {
            setOpen(!open);
        } 
        else {
            setOpen(false);
        }
    }

    return (
        <Fade direction="top" duration={1000} triggerOnce cascade>
            <CaseLink href={props.route} target={props.newTab ? '_blank' : null}>
                {!open && <CaseStudyContainerList width="80%" color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContentList onClick={props.route} styles={{transition: "height .25s linear"}} width="95%">
                        <ContainerList flexRow leftAlign>
                            <h5 style={{marginTop: "auto", marginBottom: "auto", width:"50%", padding:".5rem"}}>{props.title}</h5>
                            {props.hasData ? 
                                // 
                                <>
                                <HiOutlinePresentationChartLine style={{alignSelf: 'center', marginLeft: "5%", color: "white"}}/>
                                <Badge>Statistical Analysis</Badge> 
                                </>
                                : null}
                            <ClientText style={{alignSelf: 'center', marginLeft: "auto", color: 'black', textAlign: 'right'}}>{props.date}</ClientText>
                            {props.description && <MdOutlineDescription style={{alignSelf: 'center', marginLeft: "auto", color: "white"}}/> }
                            {/* <PrimaryButton
                            style={{alignSelf: 'center'}}
                            right
                            newTab={props.route.includes("firebasestorage.googleapis.com") ? true : false}
                            route={props.route}
                            /> */}
                        </ContainerList>
                    </CaseStudyContentList>
                </CaseStudyContainerList>}
            </CaseLink>
        </Fade>
    );
}

export default Project;