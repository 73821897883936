import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import img1 from '../images/evinfrastructure_1.jpeg'

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<p>{props.date}</p>
			<div>
				<Image src={img1} style={{margin: 0}}/>
				<i>Trabant Cars, Eastern Germany Cars from 1970s - Daniela Shuman, 2021</i>
			</div>
			
<p>
	&emsp;On June 28th, 2021, the ACEA, European Automobile Manufacturers Association, released a statement by the Director General, Eric-Mark Huitema: “Anyone who wants to buy an electric or fuel cell car depends on having reliable charging or refueling infrastructure… the time has come for governments across Europe to pick up speed in the race to greener mobility” (ACEA, 2021b). The ACEA claimed there was a “two-track” rollout of charging infrastructure across the continent. Will this division in charging risk the EU from falling behind on their 2030 objectives to electrify transportation? 
<br/>&emsp;In his full statement, the Director General claims that the electric vehicle technology is not the problem, but rather a large-scale charging infrastructure network, or lack thereof (ACEA, 2021c). He claims that there is a massive imbalance in charging stations, geographically, across the EU, between richer and poorer EU member states. The Director General argues that the progress that has been made in a few, rich member states, is distracting policy makers from progress in the poorer states, risking some states being left behind in the transition to zero-emissions mobility. Huitema urges Members of the European Parliament and the national government to address this division. 
<br/>&emsp;The EU is indeed facing a division in electric mobility charging infrastructure. 70% of the EU’s entire network of charging stations is between the Netherlands, France and Germany. The Netherlands has 66,665 charging points. In comparison with the slightly larger population, Romania has only 493 charging points. The Netherlands Central Bank made €5.7 million available to local and regional authorities for implementation of charging infrastructure for the period 2016-2018 for a total of about 10,000 public charging points (Ministry of Economic Affairs, 2017). In March 2021, Germany made €5.5 billion available for electric-car charging infrastructure (Delfs & Behrmann, 2021). There is a widely accepted view that the greatest proportion of infrastructure is needed in privately and semi-publicly accessible areas. This includes commercial buildings, garages, and car parks, for example (EuroCities, 2020). A strategy is a set of incentives to foster private investment, which was adopted in the Netherlands, as the Nationale Agenda Laadinfrastructuur (Ministry of Economic Affairs, 2017). The goal is to ensure that infrastructure is not a barrier to electrification of transport – which allows for a funnel of coordination between public and private interests. However, poorer countries don’t necessarily have the resources to subsidize this private investment in charging infrastructure, leading to a clear division.
<br/>&emsp;The Financial Times reported a couple days after the ACEA release and implied the EU is currently behind in charging infrastructure: currently with 225,000 charging stations, the EU needs 6 million to fulfill its 50% carbon emissions reduction target (Campbell, 2021). Europe’s emissions from road transport are about 20% of total EU GHG emissions and have increased significantly from 1990 (European Commission, 2021b). However, 7% of the EU’s GDP is in the automotive industry, providing jobs in manufacturing, sales, etc. – to 14.6 million Europeans. Internationally, the EU is one of the world’s largest producers of motor vehicles. This causes a relatively powerful private sector to be a potential key player in the development of charging infrastructure. 
<br/>&emsp;European Commission’s current climate change proposal is a dramatic increase in the regulation on CO2 emitting vehicles across the EU. The targets for carbon emissions have been tightened to a reduction of 90% by 2050 (European Commission, 2021b). The new amendment changes a fleet-wide target equal to a 37.5% reduction of the target in 2021 to 55% reduction in emissions by 2030. This dramatically increases the emissions reductions requirements for all member states. The specific strategies that have been developed, specifically through the Sustainable Smart Mobility Strategy (Sustainable and Smart Mobility Strategy, 2020), involve digitalization to ensure that transport equipment manufacturing can be globally competitive. The goal is by 2030 to have 1 million out of 3 million public recharging points. In response to this released strategy, the Sustainable Transport Forum created a set of recommendations for public authorities for electric recharging infrastructure for passenger cars and vans (Sustainable Transport Forum, 2021). Cross-border cooperation is critical for a successful deployment. Overall, electric charging infrastructure, is a small, but critical, component to achieve the goals of the European Union in their transportation sector. However, the current approach is to set centralized goals and give the implementation to the national governments. 
<br/>&emsp;The implementation responsibilities completely in the hands of national governments leads to the division between richer and poorer countries. The consequences of this decentralized implementation approach are particularly impactful within electric mobility deployment. Electric charging infrastructure is only useful within a network, and currently there is a relatively large externality of geographic locality, leading to a network-barrier. A solid network of charging infrastructure is a necessary pre-requisite to electric vehicle adoption. The European Court of Auditors released a study in July 2021 that found the variability of availability of charging stations between countries results in difficult travel between countries (European Court of Auditors, 2021). This travel applies to private vehicles, but also electric freight and electric supply chain vehicles. Within the study, the Court of Auditors argued this will lead to a difficulty in reaching the 2030 green transportation goals set by the European Commission. 
<br/>&emsp;The Commission has clear incentives to support adoption of charging infrastructure network at scale. The goals set by the EU in the ‘Fit for 55’ include a transition to electric mobility infrastructure (European Commission, 2021b), specifically to support the at-scale adoption of electric private vehicles and trucks (ACEA, 2021a). However, by observing the progress made in the aggregate, the Commission has avoided addressing the growing implementation inequalities. Addressing the issue of electric mobility charging infrastructure seems to be in the interest of the Commission.
<br/>&emsp;Car manufacturers also have a strong interest in good electric infrastructure. As demonstrated by the urging of the ACEA, car manufacturers need good electric charging infrastructure for the widescale adoption of electric vehicles. The demand for electric cars will remain constant without geographically reliable public infrastructure. Manufacturers are put into a bind, as they are pushed to reach higher levels of regulation put on at the EU level, however, the public infrastructure is lagging to support. 
<br/>&emsp;Finally, Nation States as well have an interest in widescale geographical adoption rather than local adoption, as well. Electric charging infrastructure is more effective if neighboring nation states also invest in the infrastructure. Travelling through countries with an electric vehicle, including private vehicles and trucks, can only be done with this cross-country network. Further, local incentives may only apply in the most populated areas. However, for widescale EV adoption, more rural areas also need subsidies to adopt the infrastructure, in fact, arguably more than the more populated areas, which can benefit from other modes of transportation (i.e., busses, trains, or even bikes). National governments have an interest in closing these regional differences but may not have the money to subsidize enough private investment.
<br/>&emsp;Who, then, should be responsible for the decisions in implementing the charging infrastructure? Currently, the nation states are in charge of the implementation. If nation states continued with the sole responsibility of implementation, we will continue to see this imbalance of charging infrastructure. If private investors took more control of charging infrastructure, the externalities might not go away completely. In less dense developments, charging infrastructure is still a necessity, but there is a higher barrier to entry for private investment. Lower capacity adoption of private vehicles means a smaller incentive for car manufacturers to also provide charging infrastructure, when higher capacity areas can be found in cities and suburban developments. Further, although there is reason for private investors to fund charging infrastructure at the network scale, it is a higher scale project, thus with higher risk and higher initial investment to build up the economy of scale. Private investors are much less likely to fund a network at the larger regional level. Private investors are much more responsive to local incentives than regional and international incentives. 
<br/>&emsp;Thus, there is an argument for the European-level government to swallow the externality and fund the network that is needed for scale adoption. The Commission has systems set up to address these forms of regional differences. The NUTS classification is a hierarchical system for dividing up the economic territory of the EU into major socio-economic regions to small regions for specific diagnosis. NUTS has been used to list 92 regions at the highest level, up to 1166 regions at the smallest geographical scale (EuroStat, 2021). Regions eligible for diagnosis are delivered funds through either the European Regional Development Fund, Cohesion Fund, European Social Fund, or the Just Transition Fund (JTF) (European Commission, 2021a). The JTF is specifically to support regions most affected by the transition towards climate neutrality. However, this isn’t being used to fund the charging infrastructure.
<br/>&emsp;If the Commission took more control of the implementation, the government could subsidize investment and provide much more network coverage than private investors. Currently, the EU sets the goals, but the implementation is sent directly to the nation states. One sensible reason for this is to encourage the most optimal solution at the more local level. And by the current evidence at the aggregate, the EU is accelerating towards its targets. Countries like Germany and the Netherlands have already reached their 2025 goal for electric vehicle adoption. However, it seems all the implementation responsibilities to the nation states results in regional divides, and some countries being left behind. Perhaps if the Commission took more control of the implementation, the EU would be more setup for reducing regional inequalities and reaching its 2030 carbon reduction goals. 

<br/>
<br/>

<h3>Bibliography:</h3>
<ol>
<li/>ACEA. (2021a, June 9). Electric trucks: New study pinpoints precise locations for charging infrastructure across EU. https://www.acea.auto/press-release/electric-trucks-new-study-pinpoints-precise-locations-for-charging-infrastructure-across-eu/
<li/>ACEA. (2021b, June 29). Risk of two-track Europe for e-mobility with sharp divisions in roll-out of chargers, auto industry warns – ACEA – European Automobile Manufacturers’ Association. ACEA - European Automobile Manufacturers’ Association. https://www.acea.auto/press-release/risk-of-two-track-europe-for-e-mobility-with-sharp-divisions-in-roll-out-of-chargers-auto-industry-warns/
<li/>ACEA. (2021c, September 29). Fit for 55: A much-needed reality check for EU policy and decision makers to keep mobility accessible – ACEA – European Automobile Manufacturers’ Association. ACEA - European Automobile Manufacturers’ Association. https://www.acea.auto/message-dg/fit-for-55-a-much-needed-reality-check-for-eu-policy-and-decision-makers-to-keep-mobility-accessible/
<li/>Campbell, P. (2021, June 28). Europe risks ‘two track’ rollout of electric car charging points | Financial Times. https://www.ft.com/content/e379d661-6908-404b-8a63-c7996787189a
<li/>Delfs, & Behrmann. (2021, March 24). Germany Offers $6.5 Billion in Electric-Car Charging Funding. Bloomberg. https://www.bloomberg.com/news/articles/2021-03-23/german-car-industry-ready-to-fulfill-tougher-eu-climate-goals
<li/>EuroCities. (2020). Www.eurocities.eu | Better alternatives for city authorities. 25.
<li/>European Commission. (2021a). The EU’s main investment policy. https://ec.europa.eu/regional_policy/en/policy/what/investment-policy/
<li/>European Commission. (2021b, July 14). REGULATION OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL  strengthening the CO2 emission performance standards for new passenger cars and new light commercial vehicles in line with the Union’s increased climate ambition. https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A52021PC0556
<li/>European Court of Auditors. (2021). Infrastructure for charging electric vehicles: More charging stations but uneven deployment makes travel across the EU complicated. Publications Office. https://data.europa.eu/doi/10.2865/51
<li/>EuroStat. (2021, January 1). Background—NUTS - Nomenclature of territorial units for statistics—Eurostat. https://ec.europa.eu/eurostat/web/nuts/background/
<li/>Ministry of Economic Affairs. (2017). Vision on the charging infrastructure for electric transport.
<li/>Sustainable and Smart Mobility Strategy. (2020, September 12). COMMUNICATION FROM THE COMMISSION TO THE EUROPEAN PARLIAMENT, THE COUNCIL, THE EUROPEAN ECONOMIC AND SOCIAL COMMITTEE AND THE COMMITTEE OF THE REGIONS. https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A52020DC0789
<li/>Sustainable Transport Forum. (2021, February 12). How to design tenders for e-charging infrastructure—New Handbook for public authorities [Text]. Mobility and Transport - European Commission. https://ec.europa.eu/transport/themes/urban/news/2021-02-15-stf-handbook_en
</ol>
</p>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;