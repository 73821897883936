import FirstBlogPostImage from '../assets/img/post_1.png'

import ElCano from '../posts/blog/Elcano.jsx'
import EVInfrastructure from '../posts/blog/EVInfrastructure.jsx'
import Sidewalks from '../posts/blog/sidewalks.jsx'
import Haussmann from '../posts/blog/haussmann.jsx'
import TemporaryBiking from '../posts/blog/TemporaryBiking.jsx'
import Slovenia from '../posts/blog/Slovenia.jsx'

import { BiPencil } from 'react-icons/bi'
import { MdElectricCar } from 'react-icons/md'
import { BsPaintBucket } from 'react-icons/bs'
import { GiDoubleStreetLights, GiDutchBike } from 'react-icons/gi'

import SloveniaImg from '../assets/img/slovenia.jpg'

const Posts = [
    // {
    //     title: "Slovenia: Unveiling My Love for this Tiny Nation",
    //     subtitle: "By: Mariana & Daniela Rutar Shuman",
    //     date: "Spring 2023",
    //     description: "",
    //     image: SloveniaImg,
    //     content: Slovenia,
    //     route: "slovenia",
    //     items: []
    // },
    {
        title: "Community Land Trusts: A Case Study in El Caño",
        subtitle: "A success story in formalizing informal settlements",
        date: "Spring 2022",
        description: "",
        image: BiPencil,
        content: ElCano,
        route: "https://www.huusl.org/journal/community-land-trusts",
        items: []
    },
    {
        title: "Temporary Urban Infrastructure: A case study in Berlin",
        subtitle: "How Temporary Mobility Infrastructure can effectively implement Urban Experimentation",
        date: "Fall 2021",
        description: "",
        image: GiDutchBike,
        content: TemporaryBiking,
        route: "temporarybiking",
        items: []
    },
    {
        title: "Electric Mobility Charging Infrastructure",
        subtitle: "An exploration of regional disparities across the EU",
        date: "Fall 2021",
        description: "",
        image: MdElectricCar,
        content: EVInfrastructure,
        route: "https://www.huusl.org/journal/how-do-i-start-investing-f9pld",
        items: []
    },
    {
        title: "Street-Art: An proposal for Boston",
        subtitle: "Street art is a powerful tool to improving urban walkability",
        date: "Spring 2021",
        description: "",
        image: BsPaintBucket,
        content: Sidewalks,
        route: "sidewalks",
        items: []
    },
    {
        title: "Bus Rapid Transit Systems in Rio de Janeiro vs. Paris ",
        subtitle: "Haussmann’s Influence on Urban Design through Boulevard Widening",
        date: "Fall 2021",
        description: "",
        image: GiDoubleStreetLights,
        content: Haussmann,
        route: "haussmann",
        items: []
    },
    {
        title: "Project Finance Impact Investing",
        subtitle: "Exploration with Raise Green, Impact Investing Group",
        date: "Spring 2021",
        description: "",
        image: GiDoubleStreetLights,
        content: null,
        route: "https://harvardcollegeimpactinvesting.medium.com/project-finance-impact-investing-with-raise-green-d24931779b54",
        items: []
    }
]

export default Posts;