import React, { useState } from 'react';
import {
    ContainerList,
    Container,
    CaseLink,
    CaseStudyContainerList,
    CaseStudyContainer,
    CaseStudyContentList,
    CaseStudyContent,
    CaseStudyImage
} from '../StyledComponents/StyledComponents';
import { ClientText } from "./Style";
import { Fade } from 'react-awesome-reveal';

import { MdOutlineDescription } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'

const Project = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        if (props.description) {
            setOpen(!open);
        } 
        else {
            setOpen(false);
        }
    }

    return (
        <Fade direction="top" duration={1000} triggerOnce cascade>
            <CaseLink target={props.newTab ? '_blank' : null}>
                {!open && <CaseStudyContainerList color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContentList onClick={toggle} styles={{transition: "height .25s linear"}}>
                        <ContainerList flexRow leftAlign>
                            <h5 style={{marginTop: "auto", marginBottom: "auto"}}>{props.title}</h5>
                            <ClientText>{props.author}</ClientText>
                            {props.description && <MdOutlineDescription style={{alignSelf: 'center', marginLeft: "auto", color: "white"}}/> }
                        </ContainerList>
                    </CaseStudyContentList>
                </CaseStudyContainerList>}
                {open && <CaseStudyContainer color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContent onClick={toggle} styles={{transition: "height .25s linear"}}>
                        <Container flexRow leftAlign>
                            <ClientText>{props.author}</ClientText>
                        </Container>
                        <div>
                            <h2>{props.title}</h2>
                            {props.length && 
                            <div style={{display:"flex", flexDirection:"horizontal", fontSize:"15px"}}> 
                                <BiTimeFive style={{color: "white", alignSelf: "center", paddingRight: "2%"}} /> 
                                <p style={{margin:0}}>{props.length} hours</p>
                            </div>}
                            <p style={{fontSize: "15px"}}>{props.description}</p>
                        </div>
                    </CaseStudyContent>
                    {props.thumbnail && 
                        <CaseStudyImage background={props.color}>
                            <img src={props.thumbnail} alt=""/>
                        </CaseStudyImage>
                    }
                </CaseStudyContainer>}
            </CaseLink>
        </Fade>
    );
}

export default Project;