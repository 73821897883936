import React, { useState } from 'react';
import {
    ContainerList,
    Container,
    CaseLink,
    CaseStudyContainerList,
    CaseStudyContainer,
    ReviewContainer,
    CaseStudyContentList,
    CaseStudyContent,
    CaseStudyImage
} from '../StyledComponents/StyledComponents';
import { ClientText } from "./Style";
import { Fade } from 'react-awesome-reveal';

import { MdOutlineDescription } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'

const Project = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        if (props.description) {
            setOpen(!open);
        } 
        else {
            setOpen(false);
        }
    }
    return (
        <Fade direction="top" duration={1000} triggerOnce cascade>
            <CaseLink target={props.newTab ? '_blank' : null}>
                <ReviewContainer style={{transition: "height 1s ease"}} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContent onClick={props.onClick} style={{transition: "height 1s ease"}}>
                        <Container flexRow leftAlign>
                                <ClientText style={{textAlign: "left"}}>{props.author}</ClientText>
                                {props.timestamp && <ClientText style={{alignSelf: 'center', marginLeft: "auto", color: "white"}}>{props.timestamp.toDate().getFullYear()}</ClientText>}
                        </Container>
                        <div>
                            <h2>{props.title}</h2>
                            {props.length && 
                            <div style={{display:"flex", flexDirection:"horizontal", fontSize:"15px"}}> 
                                <BiTimeFive style={{color: "white", alignSelf: "center", paddingRight: "2%"}} /> 
                                <p style={{margin:0}}>{props.length} hours</p>
                            </div>}
                            <Container style={{textAlign: "center" }}>
                                <CaseStudyImage background={props.color} onClick={props.onClick} style={{ width: '25%', float: props.left ? "left" : "right", margin: `1% 2% 1% 2%`}}>
                                    <img src={props.thumbnail} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </CaseStudyImage>
                                <p style={{flex: '1', textAlign: "justify"}}>{props.description}</p>
                            </Container>
                            {/*
                            <Container style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', textAlign: "center" }}>
                                <CaseStudyImage background={props.color} onClick={props.onClick} style={{ width: '25%', float: "left", flexBasis: 'auto', margin: `5%`}}>
                                    <img src={props.thumbnail} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </CaseStudyImage>
                                <p style={{flex: '1', marginLeft: '10px', textAlign: "justify"}}>{props.description}</p>
                            </Container>
                            <Container flexRow leftAlign>
                                <p style={{fontSize: "15px"}}>{props.description}</p>
                                <CaseStudyImage background={props.color} onClick={props.onClick}>
                                    <img src={props.thumbnail} alt=""/>
                                </CaseStudyImage>
                            </Container>*/}
                            
                        </div>
                        
                    </CaseStudyContent>
                    </ReviewContainer>
                {/*{!props.open && props.thumbnail && 
                        <CaseStudyImage background={props.color} onClick={props.onClick}>
                            <img src={props.thumbnail} alt=""/>
                        </CaseStudyImage>
                }
                {props.open && <CaseStudyContainer style={{margin: '5px', transition: "height 1s ease"}} color={props.color} favorite={props.tags ? props.tags.includes("favorite") : false}>
                    <CaseStudyContent onClick={props.onClick} style={{transition: "height 1s ease"}}>
                        <Container flexRow leftAlign>
                            <ClientText>{props.author}</ClientText>
                        </Container>
                        <div>
                            <h2>{props.title}</h2>
                            {props.length && 
                            <div style={{display:"flex", flexDirection:"horizontal", fontSize:"15px"}}> 
                                <BiTimeFive style={{color: "white", alignSelf: "center", paddingRight: "2%"}} /> 
                                <p style={{margin:0}}>{props.length} hours</p>
                            </div>}
                            <p style={{fontSize: "15px"}}>{props.description}</p>
                        </div>
                    </CaseStudyContent>
                    </CaseStudyContainer>}*/}
            </CaseLink>
        </Fade>
    );
}

export default Project;