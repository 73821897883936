const About = {
    about: {
        title: "I live somewhere between Sketch, JavaScript, and whiteboards.",
        description: `Hi! I’m Daniela, a proud Seattle-ite and public transportation enthusiast! I’m studying Computer Science and European Studies at Harvard. Catch me reading the next NYTimes bestseller or on top of a mountain discussing the implications of longtermism. Looking forward to meeting you!
        You can read about my hiking/traveling adventures, music and book recommendations, and some of my projects. 
        `,
        experience: [
            {
                title: "Senior Product Designer",
                company: "Google",
                date: "2019 - Present",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
                title: "Product Designer",
                company: "Dribbble",
                date: "2017 - 2019",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
                title: "Product Designer",
                company: "Facebook",
                date: "2014 - 2017",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
        ],
        education: [
            {
                degree: "MS",
                program: "Human-Centered Design & Engineering",
                school: "University of Michigan",
                date: "2019 - 2021",
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                degree: "BFA",
                program: "Graphic Design",
                school: "University of Michigan",
                date: "2009 - 2013",
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }
        ]
    },
    contact: {
        title: "Get in touch.",
        description: "I'm always interested in meeting new people. Use the contact form, or you can shoot me an email at",
        email: "hello@schrutefarms.com",
        formspree: ""
    },
    home: {
        name: "Daniela Shuman",
        title: "Personal blog where I write code and explain all the things.",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    social: {
        codepen: "stevehayes",
        instagram: "daniela.shuman",
        linkedin: "daniela-shuman"
    },

};

export default About;
