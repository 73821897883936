import React from 'react';
import {
	BodyContainer
} from '../components/StyledComponents/StyledComponents';
import Navigation from '../components/Navigation/Navigation.jsx';
import Footer from '../components/Footer/Footer.jsx';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import { PALETTE_3 } from '../assets/colors';

import { useEffect, useState } from 'react';

import { db } from '../firebase.js';
import { collection, onSnapshot } from "firebase/firestore"; 
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Hiking = () => {

	const [input, setInput]=useState('');
    const [list,setList]=useState([]);
	const [miles, setMiles] = useState(0);
	const storage = getStorage();

	useEffect(() => {
        onSnapshot(collection(db, 'hiking'), (snapshot) => {
            snapshot.docs.map(doc => {
                let data = doc.data();
                setList(prev => [...prev, data]);
				console.log(miles + data.miles)
				setMiles(miles => miles + data.miles)
				//setMiles(list.reduce(function(a, b){return a.miles + b.miles}, 0))
            })
        })
    },[input]);

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
				<h1>Climbing Mountains...</h1>
				<h4>Currently {list.length} mountains and counting</h4>
				<p style={{lineHeight: '.2'}}>And a total of {miles} miles</p>
				<VerticalTimeline>
				{list.sort((a,b) => b.timestamp.seconds - a.timestamp.seconds).map((ele) => {
					return (
						<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date={ele.date}
					iconStyle={{ background: PALETTE_3.THISTLE, color: '#fff' }}
				>
					<h3 className="vertical-timeline-element-title">{ele.name}</h3>
					<p>{ele.desc}</p>
					<img src={ele.image} width={'100%'} style={{maxHeight: '500px', objectFit: "contain"}}/>
				</VerticalTimelineElement>
					)
				})}
				</VerticalTimeline>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default Hiking;