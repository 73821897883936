import React from 'react';
import {
	BodyContainer
} from '../components/StyledComponents/StyledComponents';
import Navigation from '../components/Navigation/Navigation.jsx';
import Footer from '../components/Footer/Footer.jsx';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import { PALETTE_3 } from '../assets/colors';

import { useEffect, useState } from 'react';

import { db } from '../firebase.js';
import { collection, onSnapshot } from "firebase/firestore"; 
import { getStorage } from "firebase/storage";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Travels = () => {

	const [input, setInput]=useState('');
    const [list,setList]=useState([]);
	const storage = getStorage();

	const sorter = (a, b) => {
		return b.year - a.year;
	};

	useEffect(() => {
        onSnapshot(collection(db, 'cities'), (snapshot) => {
            snapshot.docs.map(doc => {
                let data = doc.data();
                setList(prev => [...prev, data]);//snapshot.docs.map(doc => doc.data()));
            })
        })
    },[input]);

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
				<h1>Visiting Cities...</h1>
				<div style={{borderRadius: "10%", width: '100%', overflow: 'hidden', height: '300px', display: 'flex', justifyContent: 'center', webkitFilter: "grayscale(100%)", filter: "grayscale(100%)"}}>
					<iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Sj59lopSBeV0C0Y1k5VhxAK3lLezIDk&ehbc=2E312F" 
						width="100%"
						height="600"
						pointerEvents="none"
						frameborder="0"
						style={{border:"0", visibility: "off", marginTop: '-150px'}}>
					</iframe>
				</div>
				<VerticalTimeline>
				{list.sort(sorter).map((ele) => {
					return (
						<VerticalTimelineElement
					className="vertical-timeline-element--work"
					date={ele.year}
					iconStyle={{ background: PALETTE_3.THISTLE, color: '#fff' }}
				>
					<h3 className="vertical-timeline-element-title">{ele.name}</h3>
					<p>{ele.desc}</p>
					<img src={ele.image} width={'100%'} style={{maxHeight: '500px', objectFit: "contain"}}/>
				</VerticalTimelineElement>
					)
				})}
				</VerticalTimeline>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default Travels;