import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="about" />
			<BodyContainer style={{margin: "10%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<p>{props.date}</p>
			
			<p>Streetart can be a powerful tool to improving the safety of walkable neighborhoods. Check out my project <a href="https://www.youtube.com/embed/PrWU0zw577E">here</a>...</p>
			<iframe src="https://docs.google.com/presentation/d/e/2PACX-1vR1eEUMIuXsUgt8odrfIMaz3WcMPC30FsXrLEwjNI4Ye2P17IqTG8UVI43Jy2z-B2U5dwknjYQaxaC1/embed?start=false&loop=false&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;