import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Pages
import Home from "./pages/Home.jsx";
import About from "./pages/About.jsx";
import Hiking from "./pages/Hiking.jsx";
import Travels from "./pages/Travels.jsx";
import Blog from "./pages/Blog.jsx";
import Books from './posts/books.md';
import Music from './posts/music.md';

import MusicPost from "./components/BlogPost/MusicPost.jsx"

import BlogPost from './components/BlogPost/BlogPost';
import ListPost from './components/BlogPost/ListPost';

import * as serviceWorker from "./serviceWorker";
import { AnimatePresence } from "framer-motion";
import { GlobalStyle } from "./components/StyledComponents/StyledComponents.jsx";
import { ThemeProvider } from "styled-components";
import theme from "./components/StyledComponents/Theme";

import Posts from '../src/posts/Posts';

class App extends Component {

    render () {

        if(window.location.hostname !== "localhost") {

            // Google Analytics
            //import ReactGA from "react-ga";
            
            // Google Analytics ID goes here.
            // let trackingId = "123";

            // ReactGA.initialize(trackingId);
            // ReactGA.pageview('/home');
        }


        return (
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <BrowserRouter>
                    <AnimatePresence>
                        <Switch>
                            <Route path="/home">
                                <Home />
                            </Route>

                            <Route path="/about">
                                <About />
                            </Route>

                            <Route path="/hiking">
                                <Hiking />
                            </Route>

                            <Route path="/travels">
                                <Travels />
                            </Route>

                            <Route path="/blog">
                                <Blog />
                            </Route>

                            <Route path="/books">
                                <ListPost
                                    title="Books"
                                    content={Books}
                                    id={'books'}
                                />
                            </Route>

                            <Route path="/music">
                                <MusicPost
                                    title="Music"
                                    content={Music}
                                    id={'music'}
                                />
                            </Route>

                            {Posts.map((post) => {
                                return(
                                    <Route path={`/${post.route}`}>
                                        {post.title !== "Books" && post.title !== "Music" &&
                                            <post.content
                                                title={post.title}
                                                date={post.date}
                                                id={post.route}
                                                subtitle={post.subtitle}
                                            />
                                        }
                                    </Route>
                                )
                            })}
                            <Redirect from="/" to="/home" />
                        </Switch>
                    </AnimatePresence>
                </BrowserRouter>
            </ThemeProvider>
        )
    }
}

{/*<BlogPost
        title={post.title}
        date={post.date}
        image={post.image}
        content={post.content}
/>*/}

serviceWorker.unregister();
ReactDOM.render(<App />, document.getElementById('root'))
