// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/app';
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDHWgj-LOtAZ3S7rMQB6p_y-xB8Tg8wF6E",
  authDomain: "danielashuman-35529.firebaseapp.com",
  databaseURL: "https://danielashuman-35529-default-rtdb.firebaseio.com",
  projectId: "danielashuman-35529",
  storageBucket: "danielashuman-35529.appspot.com",
  messagingSenderId: "471810374991",
  appId: "1:471810374991:web:34386046a3f01b59b2993a",
  measurementId: "G-025Z66LYWW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db }